export const roundCurrency = (amount) => {
    return Math.ceil(amount * 100) / 100;
};

export const calculateStripeFees = (amount, formData) => {
    let baseStripeFee = 2.9;
    const additionalInternationalFee = 0.8;

    if (formData.selectedCountry.value !== "CAN") {
        baseStripeFee += additionalInternationalFee;
    }

    const stripeFees = roundCurrency(amount * (baseStripeFee * 0.01) + 0.3);

    return stripeFees;
};

export const calculateOurFees = (amount) => {
    return roundCurrency(amount * 0.7 * 0.01);
};

export const calculateInitialAmountGuess = (amount, formData) => {
    const totalAmount =
        amount + calculateStripeFees(amount, formData) + calculateOurFees(amount);

    return totalAmount;
};

export const calculateTotalAmount = (initialAmount, canPayFees, formData) => {
    const amount = Number(initialAmount);

    if (isNaN(amount)) {
        return 0;
    }

    if (!canPayFees) {
        return amount;
    }

    const initialGuess = calculateInitialAmountGuess(amount, formData);
    const stripeFees = calculateStripeFees(initialGuess, formData);
    const ourFees = calculateOurFees(initialGuess);

    const remainingAmount = initialGuess - stripeFees - ourFees;

    if (remainingAmount < initialAmount) {
        return roundCurrency(initialGuess + (initialAmount - remainingAmount));
    }

    return roundCurrency(initialGuess);
};

export const extractFirstAndLastName = (fullName) => {
    if (!fullName || typeof fullName !== "string" || fullName.trim() === "") {
      return { firstName: "", lastName: "" };
    }

    const names = fullName.trim().split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : "";

    return { firstName, lastName };
  };
