import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar"
import Footer from "./Footer";
import BlueSection from "./BlueSection"
import { ReactComponent as BackgroundSVG } from "../assets/img/background2.svg";
import AuctionAccessThankYouPage from "./AuctionAccessThankYouPage";

class BuyAuctionAccessComponent extends Component {
  constructor(props) {
    super(props);

    const ticketOptions = [
      { value: "regular", label: "Regular", cost: 0 }
    ]

    const quantityOptions = [
      { value: 1, label: "1 Ticket" },
      { value: 2, label: "2 Tickets" },
      { value: 3, label: "3 Tickets" },
      { value: 4, label: "4 Tickets" },
      { value: 5, label: "5 Tickets" },
      { value: 6, label: "6 Tickets" },
      { value: 7, label: "7 Tickets" },
      { value: 8, label: "8 Tickets" },
      { value: 9, label: "9 Tickets" },
      { value: 10, label: "10 Tickets" }
    ];

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      selectedTicketQuantityOption: quantityOptions[0],
      selectedTicketOption: ticketOptions[0],
      payFees: false,
      firstName: "",
      ticketOptions: ticketOptions,
      ticketQuantityOptions: quantityOptions,
      username: "",
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };


  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
  }

  updateFirstName = (firstName) => {
    this.setState({firstName: firstName})
  }

  updateTicketOption = (value, identifier) => {
    this.setState({ [identifier]: value });
  };

  updateUsername = (value) => {
    this.setState({ username: value });
  };


  render() {
    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
            id="bg-wrap"
          ><BackgroundSVG /></div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 info">
              
              </div>
              <div className="col-lg-6 info text-light donation-form">
                {this.state.hasDonated ? <AuctionAccessThankYouPage 
                updateHasDonated={this.updateHasDonated} 
                firstName={this.state.firstName}
                username={this.state.username}
                toast={toast}  /> : (
                  <StripeContainer updateIsLoading={this.updateIsLoading} 
                  updateHasDonated={this.updateHasDonated} 
                  updateAmount={this.updateAmount}
                  payFees={this.state.payFees}
                  togglePayFees={this.togglePayFees}
                  updateFirstName={this.updateFirstName}
                  formType="AuctionAccess"
                  ticketOptions={this.state.ticketOptions}
                  updateTicketOption={this.updateTicketOption}
                  selectedTicketOption={this.state.selectedTicketOption}
                  updateUsername={this.updateUsername}
                  selectedTicketQuantityOption={this.state.selectedTicketQuantityOption}
                  ticketQuantityOptions={this.state.ticketQuantityOptions}

                  />
                )}
                
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default BuyAuctionAccessComponent;