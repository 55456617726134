import { calculateTotalAmount, extractFirstAndLastName } from './paymentUtils';


const createOtherIntentPayment = async (stripe, updateFirstName, amount, payFees, e, toast, updateHasDonated, 
  formData, ticketQty, selectedTicketOption, updateUsername) => {
    const paymentMethod = e.methodName;
    const paymentCard = e.paymentMethod.card.brand;
    const paymentMethodId = e.paymentMethod.id;

    console.log("SCREAM");
    console.log(paymentMethodId);

    const { firstName, lastName } = extractFirstAndLastName(e.payerName);
    updateFirstName(firstName);
    const originURL = window.location.href;

    const { error: backendError, clientSecret, attendee_url } = await fetch(
      `${process.env.REACT_APP_API_URL}/auction/customer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodType: "card",
          currency: "cad",
          amount: calculateTotalAmount(amount, payFees, formData) * 100,
          originalAmount: amount * 100,
          payFees: payFees,
          email: e.payerEmail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: e.payerPhone,
          addressLine1: e.paymentMethod.billing_details.address.line1,
          addressLine2: e.paymentMethod.billing_details.address.line2,
          city: e.paymentMethod.billing_details.address.city,
          province: e.paymentMethod.billing_details.address.state,
          zip: e.paymentMethod.billing_details.address.postal_code,
          originURL: originURL,
          paymentMethod: paymentMethod,
          paymentCard: paymentCard,
          paymentMethodId: paymentMethodId,
         ticketQty: ticketQty,
          ticketOption: selectedTicketOption.value,
          ticketCost: selectedTicketOption.cost,
          attendEvent: true
        }),
      }
    ).then((r) => r.json());

    if (backendError) {
      toast.error(backendError.message);
      return;
    }

    // Complete the payment request to dismiss the Apple Pay dialog
    e.complete("success");
    updateUsername(e.payerEmail);
    updateHasDonated(true);
};

export default createOtherIntentPayment;
