import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import DonationForm from "./DonationForm";
import BuyAuctionAccessForm from "./BuyAuctionAccessForm";

console.log(process.env.REACT_APP_STRIPE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeContainer = ({
  updateIsLoading,
  updateHasDonated,
  amount,
  updateAmount,
  formType,
  payFees,
  togglePayFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateUsername,
  selectedTicketQuantityOption,
  ticketQuantityOptions
}) => {

  // Render different types of DonationForms based on the 'formType' prop
  const renderDonationForm = () => {
    switch (formType) {

      case "AuctionAccess":
        return (
          <BuyAuctionAccessForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
            ticketOptions={ticketOptions}
            updateTicketOption={updateTicketOption}
            selectedTicketOption={selectedTicketOption}
            selectedTicketQuantityOption={selectedTicketQuantityOption}
            updateUsername={updateUsername}
            ticketQuantityOptions={ticketQuantityOptions}
          />
        );
      default:
        return (
          <DonationForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
          />
        );
    }
  };

  return <Elements stripe={stripePromise}>{renderDonationForm()}</Elements>;
};

export default StripeContainer;
