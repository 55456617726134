import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import BuyAuctionAccessComponent from './components/BuyAuctionAccessComponent';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BuyAuctionAccessComponent />} />
      </Routes>
    </div>
  );
}

export default App;
