import React, { Component } from "react";
import "../assets/css/ThankYouPage.css";

class AuctionAccessThankYouPage extends Component {
  constructor(props) {
    super(props);


    this.state = {
      accessCode: "",
      verified: false
    };
  }


  handleDonateAgain = (e) => {
    e.preventDefault();

    this.props.updateHasDonated(false);
  };

  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = `/register/${this.props.registrationURL}`; // Redirect to Big Sisters website
  };

  handleConfirmation = async (e) => {
    e.preventDefault();

    const body = {
      "username": this.props.username,
      "confirmation_code": this.state.accessCode
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/confirm-registration`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      this.props.toast.error("Registration Failed. Please try again later.");
    }
    this.setState({verified: true});
  }


  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = "https://cbo.io/bidapp/main.php?slug=soiree2024bslm"; // Redirect to Big Sisters website
  }

  render() {
    return (
      <div className="thank-you-page">
        <div className="form-box">
          <form>
            <h3 style={{ color: "#2dccd3" }}>
              {this.props.firstName ? (
                <>Thank You, {this.props.firstName}!</>
              ) : (
                <>Thank You!</>
              )}
            </h3>
         <p style={{ color: "#232323" }}>
            You're ready to start bidding. Visit the <a href="https://cbo.io/bidapp/main.php?slug=soiree2024bslm" style={{ color: "#232323" }}>Auction Site</a> to begin bidding!
          </p>

          <button style={{textTransform: "none"}} className="btn btn-theme effect btn-md continue-on" type="submit"  onClick={this.handleContinue}>
            Start Bidding!
            </button>
            <br/>



            <p style={{ color: "#232323" }}>
              Supporters like you make it possible for Big Sisters to bring
              mentorship programs to girls and youth in our community. Thank you
              for helping to ignite the potential of youth across the Lower
              Mainland.
            </p>
          </form>
        </div>
      </div>
    );
  }
}

export default AuctionAccessThankYouPage;